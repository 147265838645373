import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing, palette}) => ({
  boxBorder: {
    borderBottom: `1px solid ${palette.grey[200]}`,
  },
  box: {
    marginTop: spacing(2),
  },
}))

export default function MenuGroup(props: {
  children: React.ReactElement | React.ReactNode
  noBorder?: boolean
}) {
  const {children, noBorder} = props

  const classes = useStyles()

  const className = noBorder
    ? classes.box
    : clsx(classes.boxBorder, classes.box)

  return <div className={className}>{children}</div>
}
