export const sections = [
  {
    title: 'Places',
    isCottageRental: true,
    links: [
      {
        label: 'Bas-Saint-Laurent',
        path: '/bas-saint-laurent',
        label_en: 'Bas-Saint-Laurent',
        path_en: '/bas-saint-laurent',
      },
      {
        label: 'Capitale-Nationale',
        path: '/capitale-nationale',
        label_en: 'Capitale-Nationale',
        path_en: '/capitale-nationale',
      },
      {
        label: 'Centre-du-Québec',
        path: '/centre-du-quebec',
        label_en: 'Centre-du-Québec',
        path_en: '/centre-du-quebec',
      },
      {
        label: 'Charlevoix',
        path: '/charlevoix',
        label_en: 'Charlevoix',
        path_en: '/charlevoix',
      },
      {
        label: 'Chaudière-Appalaches',
        path: '/chaudiere-appalaches',
        label_en: 'Chaudière-Appalaches',
        path_en: '/chaudiere-appalaches',
      },
      {
        label: 'Estrie/Cantons-de-l’est',
        path: '/estrie',
        label_en: 'Estrie/Cantons-de-l’est',
        path_en: '/estrie',
      },
      {
        label: 'Gaspésie–Îles-de-la-Madeleine',
        path: '/gaspesie',
        label_en: 'Gaspésie–Îles-de-la-Madeleine',
        path_en: '/gaspesie',
      },
      {
        label: 'Lanaudière',
        path: '/lanaudiere',
        label_en: 'Lanaudière',
        path_en: '/lanaudiere',
      },
      {
        label: 'Laurentides',
        path: '/laurentides',
        label_en: 'Laurentides',
        path_en: '/laurentides',
      },
      {
        label: 'Mauricie',
        path: '/mauricie',
        label_en: 'Mauricie',
        path_en: '/mauricie',
      },
      {
        label: 'Montérégie',
        path: '/monteregie',
        label_en: 'Montérégie',
        path_en: '/monteregie',
      },
      {
        label: 'Outaouais',
        path: '/outaouais',
        label_en: 'Outaouais',
        path_en: '/outaouais',
      },
      {
        label: 'Saguenay-Lac-Saint-Jean',
        path: '/saguenay',
        label_en: 'Saguenay-Lac-Saint-Jean',
        path_en: '/saguenay',
      },
    ],
  },
  {
    title: 'Experiences',
    isCottageRental: false,
    links: [
      {
        label: 'Mini chalets à louer',
        path: '/mini-chalets-a-louer',
        label_en: 'Tiny cottages for rent',
        path_en: '/tiny-cottages-for-rent',
      },
      {
        label: 'Chalets au bord de l’eau',
        path: '/chalets-a-louer-bord-de-leau',
        label_en: 'Cottages to rent Waterfront',
        path_en: '/cottages-to-rent-waterfront',
      },
      {
        label: 'Chalets avec spa',
        path: '/chalets-a-louer-avec-spa',
        label_en: 'Cottages to rent with spa',
        path_en: '/cottages-to-rent-with-spa',
      },
      {
        label: 'Chalets où les chiens sont permis',
        path: '/chalets-a-louer-chien-permis',
        label_en: 'Cottages to rent dog allowed',
        path_en: '/cottages-to-rent-dog-allowed',
      },
      {
        label: 'Chalets avec piscine',
        path: '/chalets-a-louer-avec-piscine',
        label_en: 'Cottages to rent with swimming pool',
        path_en: '/cottages-for-rent-with-swimming-pool',
      },
      {
        label: 'Chalets de ski',
        path: '/chalets-de-ski-a-louer',
        label_en: 'Ski cabins for rent',
        path_en: '/ski-cabins-for-rent',
      },
      {
        label: 'Chalets de luxe',
        path: '/chalets-de-luxe-a-louer',
        label_en: 'Chalets de luxe',
        path_en: '/chalets-de-luxe-a-louer',
      },
      {
        label: 'Chalets en bois ronds',
        path: '/chalets-en-bois-rond',
        label_en: 'Log cabins for rent',
        path_en: '/log-cabins-for-rent',
      },
      {
        label: 'Chalets rustiques à louer',
        path: '/chalets-rustiques-a-louer',
        label_en: 'Luxury cottages to rent',
        path_en: '/luxury-cottages-to-rent',
      },
      {
        label: 'Chalets pour mariage',
        path: '/grand-chalet-a-louer-pour-mariage',
        label_en: 'Huge chalet for rent for wedding',
        path_en: '/huge-chalet-for-rent-for-wedding',
      },
      {
        label: 'Chalet motoneige',
        path: '/chalets-a-louer-avec-motoneige',
        label_en: 'Cottages with snowmobile trails',
        path_en: '/cottages-for-rent-for-snowmobiles',
      },
      {
        label: 'Chalets en location long terme',
        path: '/chalets-a-louer-long-terme',
        label_en: 'Long term cottages for rent',
        path_en: '/long-term-cottages-for-rent',
      },
    ],
  },
  {
    title: ' ',
    isCottageRental: false,
    links: [
      {
        label: 'Chalets corporatifs',
        path: '/chalets-corporatifs-a-louer',
        label_en: 'Corporate cottages for rent',
        path_en: '/corporate-cottages-for-rent',
      },
      {
        label: 'Massif de Charlevoix',
        path: '/chalets-a-louer/charlevoix/massif-de-charlevoix',
        label_en: 'The Charlevoix Massif',
        path_en: '/cottage-rental/charlevoix/charlevoix-massif',
      },
      {
        label: 'Chalets pour le télétravail',
        path: '/chalets-a-louer-pour-le-teletravail',
        label_en: 'Work from the Chalet',
        path_en: '/work-from-home-cottages-for-rent',
      },
      {
        label: 'Chalets avec spa et sauna',
        path: '/chalets-a-louer-avec-spa-et-sauna',
        label_en: 'Chalets with spa and sauna',
        path_en: '/chalets-to-rent-with-spa-and-sauna',
      },
      {
        label: 'Toutes nos expériences →',
        path: '/chalets-a-louer/experiences',
        label_en: 'All our experiences →',
        path_en: '/cottage-rental/experiences',
      },
    ],
  },
]
