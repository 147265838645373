import sortBy from 'lodash-es/sortBy'
import {FileLocation} from 'src/types/utils'

export function pictureFormatToSrcSet(
  url: string | undefined,
  formats?: number[] | undefined,
  maxWidth?: number,
) {
  if (!url || !formats || !Array.isArray(formats)) {
    return undefined
  }

  if (typeof maxWidth === 'number' && formats.length > 1) {
    //if we want image bigger than the viewport, we reduced it
    if (typeof window !== 'undefined') {
      if (maxWidth > window.innerWidth) {
        maxWidth = window.innerWidth
      }
    }

    const srcSetFormats: number[] = []
    const oneXFormat = getFormatForWidth(formats, maxWidth)

    srcSetFormats.push(oneXFormat.format)

    //if not the last format
    if (oneXFormat.idx !== formats.length - 1) {
      const width = formats[oneXFormat.idx + 1]

      //if size is over 2x, dont render
      if (width < maxWidth * 2) {
        const twoXFormat = getFormatForWidth(formats, width)
        srcSetFormats.push(twoXFormat.format)
      }
    }

    return getSrcSet(url, srcSetFormats)
  }

  return getSrcSet(url, formats, true)
}

export function formattedPhoto(photo: FileLocation, maxWidth?: number) {
  const {formats, name, url} = photo

  if (!formats?.length || !maxWidth) {
    return {
      name,
      url,
    }
  }

  let finalImage = getFormatForWidth(formats, maxWidth)

  //will only run on browser :/
  if (typeof window !== 'undefined') {
    let pixelRatio = window.devicePixelRatio
    if (pixelRatio > 1) {
      pixelRatio = pixelRatio - 0.5 //lower pixel ratio by 0.5 to get better/lower image
    }
    finalImage = getFormatForWidth(formats, maxWidth * pixelRatio)
  }

  return {
    name,
    url: getFormatUrl(url, finalImage.format),
  }
}

function getFormatUrl(url: string, format: number) {
  const extension = url.split('.').pop()
  const baseUrl = url.replace(`.${extension}`, '')
  return `${baseUrl}-${format}.${extension}`
}

export function getFormatUrls(url: string, formats: number[]) {
  return formats.map((format: number) => getFormatUrl(url, format))
}

function getSrcSet(url: string, formats: number[], useWidth = false) {
  return formats.reduce((acc: string, format: number, idx: number) => {
    let size = useWidth ? `${format + 256}w` : `${idx + 1}x`
    acc += `${getFormatUrl(url, format)} ${size}`
    //add , to everything except last one
    if (idx !== formats.length - 1) {
      acc += ', '
    }
    return acc
  }, '')
}

function getFormatForWidth(formats: number[], width: number) {
  let diffs = formats.map((f, idx) => {
    return {
      idx,
      diff: Math.abs(width - f),
    }
  })

  diffs = sortBy(diffs, 'diff')

  //grab format from the smallest diff of the width wanted
  return {
    idx: diffs[0].idx,
    format: formats[diffs[0].idx],
  }
}

export function toBase64(str: string) {
  return typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str)
}

export function getLoadingSvg(w: number, h: number) {
  return `
    <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient id="g">
        <stop stop-color="#f5f6f7" offset="20%" />
          <stop stop-color="#eee" offset="50%" />
          <stop stop-color="#f5f6f7" offset="70%" />
        </linearGradient>
      </defs>
      <rect width="${w}" height="${h}" fill="#f5f6f7" />
      <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
      <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
    </svg>`
}

export function getBlurDataUrl(width: number, height: number) {
  return `data:image/svg+xml;base64,${toBase64(getLoadingSvg(width, height))}`
}
