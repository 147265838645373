import LinearProgress from '@material-ui/core/LinearProgress'

export default function Loading(props: {loading?: boolean}) {
  const opacity = props.loading ? 1 : 0

  if (!props.loading) {
    return null
  }

  return (
    <div style={{width: '100%', opacity}}>
      <LinearProgress aria-label="linear progress" />
    </div>
  )
}
