import {SvgIconProps} from '@material-ui/core/SvgIcon'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'

const ArrowUpIcon: React.FC<SvgIconProps> = ({style, ...props}) => {
  return (
    <KeyboardArrowUp
      fontSize="inherit"
      style={{fontSize: '14px', ...style}}
      {...props}
    />
  )
}

export default ArrowUpIcon
