import {makeStyles} from '@material-ui/core/styles'
import {interFontFamily} from 'src/components/materialTheme/typography'

export const useStyles = makeStyles(
  ({palette, shape, spacing}) => ({
    count: {
      bottom: spacing(1.5),
      position: 'absolute',
      right: spacing(2.5),
    },
    flexWrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
    },
    textarea: {
      border: `1px solid ${palette.divider}`,
      borderRadius: shape.borderRadius,
      fontSize: 14,
      fontFamily: interFontFamily,
      marginTop: spacing(0.5),
      // minHeight: '132px',
      padding: spacing(1.5, 2.5),
      outline: 'none',
      resize: 'none',
      '&:focus': {
        borderWidth: `1.5px`,
      },
      '&[aria-invalid="true"]': {
        borderColor: `${palette.error.main}`,
      },
      '&:focus[aria-invalid="false"]': {
        borderColor: `${palette.primary.main}`,
      },
      '&::placeholder': {
        color: palette.grey[400],
      },
    },
  }),
  {index: 1},
)
