import {
  ADMIN_GUEST_BOOKINGS_PAGE,
  ADMIN_GUEST_FAVORITES_PAGE,
  ADMIN_GUEST_MESSAGES_PAGE,
  ADMIN_INBOX_PAGE,
  ADMIN_PAGE,
  ADMIN_PROPERTIES_PAGE,
  ADMIN_RESERVATIONS_PAGE,
} from 'src/constants/route'

export const GUEST_MENU_ITEMS = [
  {
    label: 'messages',
    icon: 'message',
    link: ADMIN_GUEST_MESSAGES_PAGE,
  },
  {
    label: 'bookings',
    icon: 'booking',
    link: ADMIN_GUEST_BOOKINGS_PAGE,
  },
  {
    label: 'favorites',
    icon: 'favorite',
    link: ADMIN_GUEST_FAVORITES_PAGE,
  },
]

export const HOST_MENU_ITEMS = [
  {
    label: 'overview',
    icon: 'overview',
    link: ADMIN_PAGE,
  },
  {
    label: 'inbox',
    icon: 'inbox',
    link: ADMIN_INBOX_PAGE,
  },
  {
    label: 'reservations',
    icon: 'reservations',
    link: ADMIN_RESERVATIONS_PAGE,
  },
  {
    label: 'properties',
    icon: 'host',
    link: ADMIN_PROPERTIES_PAGE,
  },
]
