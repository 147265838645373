import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Menu, {MenuProps} from '@material-ui/core/Menu'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import AppLink from 'src/components/elements/Link'
import {useCurrentLocale} from 'src/hooks/locale'
import {
  BASE_URL,
  PROPERTY_PAGE_CHALETS_A_LOUER,
  PROPERTY_PAGE_COTTAGE_RENTAL,
} from 'src/constants/route'
import {LOCALE_EN} from 'src/constants/locale'
import {sections} from './data'
import {useEffect, useState} from 'react'

interface StyleProps {
  isTop: boolean | undefined
}

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({breakpoints, spacing, shadows, palette}) => ({
    gridContainer: {
      margin: 0,
      width: '100%',
      maxHeight: '100%',
      '& li': {
        listStyle: 'none',
      },
    },
    paper: ({isTop}: StyleProps) => ({
      background: 'white', //isTop ? 'black' : 'white',
      marginTop: spacing(2),
      maxHeight: 'fit-content',
      '& .MuiPopover-paper': {
        boxShadow: shadows[2],
      },
      [breakpoints.down('md')]: {
        maxHeight: 'calc(100vh - 16px) !important',
        marginTop: spacing(1),
      },
    }),
    list: {
      padding: 0,
    },
    closeIcon: {
      display: 'none',
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
      fontSize: 16,
      [breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    text: ({isTop}: StyleProps) => ({
      color: palette.text.primary, //isTop ? 'white' : 'black',
    }),
  }),
  {index: 1},
)

//todo convert this to not using menu
export default function DiscoverPlacesMenu(
  props: {onClose: () => void; isTop?: boolean} & MenuProps,
) {
  const {onClose, isTop} = props

  const classes = useStyles({isTop})

  const [showClientSideComponents, setShowClientSideComponents] =
    useState<boolean>(false)

  useEffect(() => {
    setShowClientSideComponents(true)
  }, [])

  if (!showClientSideComponents) {
    return null
  }

  return (
    <>
      <Content isSeo isTop={isTop} />
      <Menu
        classes={{
          paper: classes.paper,
          list: classes.list,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        MenuListProps={{onMouseLeave: onClose}}
        getContentAnchorEl={null}
        {...props}
      >
        <Grid className={classes.gridContainer} container spacing={4}>
          <Content isTop={isTop} />
        </Grid>
        <IconButton className={classes.closeIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Menu>
    </>
  )
}

function Content(props: {isSeo?: boolean; isTop?: boolean}) {
  const {isSeo, isTop} = props

  const classes = useStyles({isTop})
  const currentLocale = useCurrentLocale()

  const hiddenStyle = isSeo ? {display: 'none'} : {}

  const baseURL =
    currentLocale === LOCALE_EN ? `${BASE_URL}/${LOCALE_EN}` : BASE_URL

  const baseURLForCottageRental =
    currentLocale === LOCALE_EN
      ? `${baseURL}/${PROPERTY_PAGE_COTTAGE_RENTAL}`
      : `${baseURL}/${PROPERTY_PAGE_CHALETS_A_LOUER}`

  return (
    <Grid
      className={classes.gridContainer}
      container
      spacing={4}
      style={hiddenStyle}
    >
      <Grid className={classes.gridContainer} container spacing={4}>
        {sections.map(({title, links, isCottageRental}) => (
          <Grid item xs={12} sm={6} lg={4} key={title} component="ul">
            <Typography
              color="textSecondary"
              style={{marginBottom: 16}}
              variant="subtitle2"
              className={classes.text}
              component="span"
            >
              {title.toUpperCase()}
            </Typography>
            {links.map(({label, path, label_en, path_en}) => {
              const labelToSet = currentLocale === LOCALE_EN ? label_en : label
              const pathToSet = currentLocale === LOCALE_EN ? path_en : path

              return (
                <li key={label}>
                  <AppLink
                    href={`${
                      isCottageRental ? baseURLForCottageRental : baseURL
                    }${pathToSet}`}
                    style={{marginBottom: 8}}
                    variant="body2"
                    className={classes.text}
                  >
                    {labelToSet}
                  </AppLink>
                </li>
              )
            })}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
